import { makeStyles } from '@mui/styles';

export const usePolicyPageStyles = makeStyles(() => ({
  infoCard: {
    maxWidth: '70rem',
    margin: '0 auto',
    padding: '2.5rem 5rem',
  },
  heading: {
    marginTop: '1rem',
  },
  body: {
    lineHeight: '2rem !important',
    marginBottom: '1rem',
    // Attempted to raise specificity as below. Not taking hold.
    /* '&.MuiTypography-root.MuiTypography-body1': {
      lineHeight: '2rem',
      }, */
  },
  title: {
    lineHeight: '2rem !important',
    fontWeight: 'bold',
  },
  subtitle: {
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  link: {
    marginLeft: '0.25rem',
    fontSize: '1rem',
    color: '#1eef97',
  },
  list: {
    lineHeight: '2rem',
    fontSize: '1rem',
  },
}));
