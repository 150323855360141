// import { Entity } from '@backstage/catalog-model';
import React from 'react';
import { Link } from '@backstage/core-components';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

type ForbidenAccessProps = {
  children?: React.ReactNode;
  entityOwner: string;
};

function ForbidenAccess({ entityOwner }: ForbidenAccessProps) {
  return (
    <Card>
      <CardContent>
        <div
          id="forbiden-access-bg"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backdropFilter: 'blur(5px)',
            width: '100%',
            zIndex: 10,
            backgroundColor: 'rgba(10, 10, 10, 0.3)',
          }}
        >
          <div
            id="forbidden-access-note-text"
            style={{
              color: 'white',
              textAlign: 'center',
              border: '2px solid red',
              padding: '2px',
              backgroundColor: 'rgba(100, 100, 100, 0.9)',
              margin: '10vh',
            }}
          >
            <Typography variant="body2" color="white" paragraph>
              You are not authorized to view this page. Please reach out to the{' '}
              <Link to={entityOwner}>owners</Link> of this content to request
              access
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default ForbidenAccess;
