import { makeStyles } from '@mui/styles';

export const useLabeledSwitchStyles = makeStyles(() => ({
  switchContainer: {
    position: 'relative',
    padding: '.25rem',
    width: '300px',
    height: '50px',
    border: '1.5px solid #9487ED',
    borderRadius: '1000px',
  },
  switch: {
    position: 'absolute',
    left: '2%',
    padding: '1.22rem',
    width: '145px',
    backgroundColor: '#442EE0',
    borderRadius: '1000px',
    zIndex: '0',
    transition: 'left .5s ease-in-out',
    '&:hover': {
      backgroundColor: '#442EE0',
    },
  },
  switched: {
    left: '50%',
    transition: 'left .5s ease-in-out',
  },
  optionsContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  optionBox: {
    textAlign: 'center',
    paddingTop: '.5rem',
    display: 'flex',
    justifyContent: 'center',
  },
  optionLabel: {
    fontWeight: 'bold',
    fontSize: '.875rem',
    zIndex: '1',
    color: '#fff',
  },
}));
