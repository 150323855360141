import { makeStyles } from '@mui/styles';

// * Custom Styles
export const useSignInPageStyles = makeStyles(() => ({
  mainWrapper: {
    width: 'min-content',
  },
  cariadLogoWrapper: {
    width: '650px',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    width: 'calc(100% - 85px)',
  },
  subtitle: {
    fontWeight: '800',
    fontSize: '2.5rem',
    color: '#f9f9f9',
    '@media (max-width: 1600px)': {
      fontSize: '2rem',
    },
  },
  copy: {
    fontSize: '1.5rem',
    lineHeight: '3rem',
    color: '#f9f9f9',
    '@media (max-width: 1600px)': {
      fontSize: '1.25rem',
      lineHeight: '2rem',
    },
  },
  radioLabel: {
    color: '#f9f9f9',
    '& .v5-MuiFormControlLabel-label': {
      fontSize: '1.5rem',
      '@media (max-width: 1600px)': {
        fontSize: '1.25rem',
      },
    },
  },
  customRadio: {
    '& .v5-MuiSvgIcon-root': {
      fontSize: '2rem',
      '@media (max-width: 1600px)': {
        fontSize: '1.5rem',
      },
    },
    color: '#1EEF97',
    width: '50px',
    '&.Mui-checked': {
      color: '#1EEF97',
    },
  },
  base: {
    minWidth: '650px',
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
    paddingLeft: '100px',
    '@media (max-width: 900px)': {
      paddingLeft: '50px',
    },
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
  },
  linksContainer: {
    display: 'flex',
    gap: '2rem',
    position: 'absolute',
    left: '100px',
    bottom: '50px',
    '@media (max-width: 1200px)': {
      bottom: '25px',
    },
  },
  link: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    color: '#f9f9f9',
    textTransform: 'none',
  },
  button: {
    width: '100%',
    borderRadius: '10px',
    backgroundColor: '#1EEF97',
    textTransform: 'none',
    color: '#373741',
    '&:hover': {
      backgroundColor: '#78F5C1',
    },
    '&:active': {
      backgroundColor: '#0DBC73',
    },
  },
}));
