import React, { Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import HelpIcon from '@mui/icons-material/Help';
import { SidebarItem } from '@backstage/core-components';
import { useSidebarOpenState } from '@backstage/core-components';
import PopupMenu from '../reusable/popup-menu/PopupMenu';
import { sidebarPopupMenuOptions } from './sidebarOptions';

const useStyles = makeStyles((theme: Theme) => ({
  arrowIcon: {
    color: '#B5B5B5',
    position: 'absolute',
    top: '.75rem',
  },
  supportContainer: {
    position: 'relative',
  },
  supportItem: {
    borderRadius: '0',
  },
  supportItemActive: {
    '&.MuiButtonBase-root': {
      backgroundColor: theme.palette.mode === 'light' ? '#424242' : '#404040',
    },
    borderRadius: '0',
  },
}));

interface SidebarSupportSubmenuProps {
  isHoveredOn: boolean;
  supportRef: React.MutableRefObject<HTMLElement | undefined>;
  popupOpen: boolean;
  onSetIsHoveredOn: Dispatch<SetStateAction<boolean>>;
  onHandleClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
  onHandlePopupClickAway: () => void;
  onHandlePopupEnter: (
    event?: React.PointerEvent<HTMLElement> | undefined,
  ) => void;
  onHandlePopupLeave: () => void;
}

const SidebarSupportSubmenu = (props: SidebarSupportSubmenuProps) => {
  const {
    isHoveredOn,
    supportRef,
    popupOpen,
    onSetIsHoveredOn,
    onHandleClick,
    onHandlePopupClickAway,
    onHandlePopupEnter,
    onHandlePopupLeave,
  } = props;
  const classes = useStyles();
  const { isOpen } = useSidebarOpenState();
  // eslint-disable-next-line no-console
  console.log('isOpen', isOpen);
  return (
    <>
      <div
        className={classes.supportContainer}
        onPointerLeave={() => onSetIsHoveredOn(false)}
      >
        <SidebarItem
          onClick={onHandleClick}
          icon={() => <HelpIcon />}
          text="Support"
          className={
            isHoveredOn ? classes.supportItemActive : classes.supportItem
          }
        />
        {!isHoveredOn && (
          <ArrowRightIcon
            className={classes.arrowIcon}
            sx={isOpen ? { right: '1.5rem' } : { right: '.25rem' }}
          />
        )}
      </div>
      <PopupMenu
        options={sidebarPopupMenuOptions}
        open={popupOpen}
        anchorEl={supportRef.current}
        onClickAway={onHandlePopupClickAway}
        onEnter={onHandlePopupEnter}
        onLeave={onHandlePopupLeave}
      />
    </>
  );
};

export default SidebarSupportSubmenu;
