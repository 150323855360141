import React from 'react';
import { Helmet } from 'react-helmet';
import { Page, Content } from '@backstage/core-components';
import { Link } from '@backstage/core-components';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import temporaryScreenshot from './assets/temp-xelerate-screenshot.png';
import LinkButton from '../reusable/link-button/LinkButton';
import FeatureCard from './components/FeatureCard';
import gradient from './assets/gradient.png';
import { featureCards } from './constants/feature-card.constant';
import Testimonials from './components/Testimonials';
import { useAboutPageStyles } from './styles/about-page.styles';
import { userTestimonials } from './constants/user-testimonials.constant';
import CallToActionCard from '../reusable/call-to-action-card/CallToActionCard';
import { aboutPageCopy, footerCtas } from './constants/about-page.constants';

const AboutPage = () => {
  const classes = useAboutPageStyles();
  return (
    <Page themeId="home">
      <Helmet titleTemplate="%s">
        <title>About | CARIAD Xelerate Backstage</title>
      </Helmet>
      <Content noPadding className={classes.topContainer}>
        <Container className={classes.layoutContainer}>
          <Box component="section" className={classes.landingSection}>
            <CardMedia
              className={classes.bgGradient}
              component="img"
              image={gradient}
            />
            <Typography className={classes.heading1}>
              Accelerate CARIAD Now
            </Typography>
            <Typography
              className={`${classes.heading3} ${classes.landingCopy}`}
            >
              XELERATE simplifies onboarding, centralizes documentation, and
              provides access to all developer assets, empowering developers to
              achieve more.
            </Typography>
            <Box className={classes.ctaLinks}>
              <LinkButton
                to="/catalog-import"
                label="Publish a Project"
                solid
              />
              <LinkButton to="/catalog" label="Explore Catalog" />
            </Box>
            <Box className={classes.screenshotContainer}>
              <CardMedia
                component="img"
                image={temporaryScreenshot}
                // height="500px"
              />
            </Box>
          </Box>
          <Box component="section" className={classes.landingSection}>
            <Typography className={classes.heading1}>
              Need to Streamline,
              <br />
              Standardize, and Succeed?
            </Typography>
            <Typography className={`${classes.body} ${classes.landingCopy}`}>
              XELERATE equips developers with an intuitive portal that minimizes
              <br /> onboarding delays and centralizes critical resources,
              enhancing their ability
              <br /> to deliver solutions efficiently and effectively.
            </Typography>
          </Box>
          <Box className={classes.featuresSection}>
            <FeatureCard content={featureCards.SERVICE_CATALOG} optionSwitch />
            <FeatureCard
              content={featureCards.TEMPLATES}
              optionSwitch
              inverted
            />
            <FeatureCard content={featureCards.PLUGINS} />
            <FeatureCard
              content={featureCards.TECHNICAL_DOCUMENTATION}
              inverted
            />
          </Box>
          <Box component="section" className={classes.testimonialsSection}>
            <Testimonials userTestimonials={userTestimonials} />
          </Box>
          <Box component="section" className={classes.footerSection}>
            <CallToActionCard
              title={aboutPageCopy.footerTitle}
              subtitle={aboutPageCopy.footerSubtitle}
              ctas={footerCtas}
              gradient
              cardPadding="7.5rem 9.75rem"
            />
          </Box>
          <Box component="section" className={classes.linksSection}>
            <Box className={classes.linksContainer}>
              <Link className={classes.link} to="/privacy-policy">
                Privacy Policy
              </Link>
              <Divider orientation="vertical" />
              <Link
                className={classes.link}
                to="https://cariad.technology/de/en/legal-notice.html"
              >
                Legal Notice
              </Link>
              <Divider orientation="vertical" />
              <Link className={classes.link} to="/cookie-policy">
                Cookie Policy
              </Link>
            </Box>
          </Box>
        </Container>
      </Content>
    </Page>
  );
};

export default AboutPage;
