import serviceCatalogImage from '.././assets/service-catalog.png';
import teamplatesImage from '.././assets/templates.png';
import pluginsImage from '.././assets/plugins.png';
import techdocsImage from '.././assets/tech-docs.png';

export const featureCards = {
  SERVICE_CATALOG: {
    FEATURE: 'SERVICE CATALOG',
    TITLE: 'Explore Our Ecosystem',
    COPY_CONSUMERS:
      'Our Service Catalog simplifies the discovery and management of a wide range of services. Whether you need libraries, websites, or APIs, the catalog provides a detailed overview of available resources. This consolidation enables efficient management of dependencies, ensuring that no valuable software is overlooked or forgotten.',
    COPY_PUBLISHERS:
      'Our Service Catalog streamlines the management and sharing of your services. It provides a clear, organized view of all the services your team owns and maintains. By centralizing service ownership and dependencies, you enhance transparency and collaboration across teams, enabling the efficient management of a large number of services.',
    CTA: 'Learn more',
    CTA_HREF: '/catalog/default/component/xelerate/docs/catalog/',
    IMAGE: serviceCatalogImage,
  },
  TEMPLATES: {
    FEATURE: 'TEMPLATES',
    TITLE: 'Streamline Your Workflow with Software Templates',
    COPY_CONSUMERS:
      'Our Software Templates allow developers to launch new projects with organizational best practices integrated from the start. With a single click, you can initiate a microservice that comes with a pre-configured repository, CI pipeline, and standardized settings. This ensures that your team can focus on coding the right away, benefiting from a consistent setup process.',
    COPY_PUBLISHERS:
      'Software Templates guarantee that all new services comply with company-wide standards, simplifying the task of managing a high-quality, uniform codebase. Enterprise architects and SMEs can define and enforce these standards across the organization, ensuring consistency and best practices are maintained. This simplifies smoother onboarding for new developers & reduces setup times.',
    CTA: 'Learn more',
    CTA_HREF: '/catalog/default/component/xelerate/docs/est-01/',
    IMAGE: teamplatesImage,
  },
  PLUGINS: {
    FEATURE: 'PLUGINS',
    TITLE: 'Leverage & Contribute Plugin Extensions',
    COPY_CONSUMERS:
      'Transform your development workflow effortlessly with our plugin ecosystem. Customize your experience with ease by building internal plugins tailored to your infrastructure, or contribute to our ecosystem, enriching the entire community. Experience a unified developer portal that serves as your single pane of glass for all your organization’s assets.',
    COPY_PUBLISHERS:
      'Transform your development workflow effortlessly with our plugin ecosystem. Customize your experience with ease by building internal plugins tailored to your infrastructure, or contribute to our ecosystem, enriching the entire community. Experience a unified developer portal that serves as your single pane of glass for all your organization’s assets.',
    CTA: 'Learn more',
    CTA_HREF: '/catalog/default/component/xelerate/docs/catalog/',
    IMAGE: pluginsImage,
  },
  TECHNICAL_DOCUMENTATION: {
    FEATURE: 'TECHNICAL DOCUMENTATION',
    TITLE: 'Elevate Your Tech Docs',
    COPY_CONSUMERS:
      'Each project built with our templates automatically includes a TechDocs site at no extra cost. Our integrated docs-like-code system allows developers to maintain documentation in Markdown files directly with their code. With all documentation centralized in our portal, finding any TechDoc is quick and straightforward.',
    COPY_PUBLISHERS:
      'Each project built with our templates automatically includes a TechDocs site at no extra cost. Our integrated docs-like-code system allows developers to maintain documentation in Markdown files directly with their code. With all documentation centralized in our portal, finding any TechDoc is quick and straightforward.',
    CTA: 'Learn more',
    CTA_HREF: '/catalog/default/component/xelerate/docs/guide-publishing/',
    IMAGE: techdocsImage,
  },
};
