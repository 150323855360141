import { makeStyles } from '@mui/styles';

export const useUserCardStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
  },
  gradientLeft: {
    background:
      'linear-gradient(90deg, rgba(29,6,56,1) 0%, rgba(29,6,56,1) 10%, rgba(0,0,0,0) 100%)',
  },
  gradientRight: {
    background:
      'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(29,6,56,1) 90%, rgba(29,6,56,1) 100%)',
  },
  cardContainer: {
    position: 'relative',
    border: '1px solid #9487ED',
    minWidth: '25rem',
    padding: '1.5rem',
    borderRadius: '1.25rem',
    backgroundColor: 'transparent',
    display: 'block',
    zIndex: 1,
  },
  user: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1.5rem',
    padding: '0',
    marginBottom: '2rem',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  userName: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: '#fff',
  },
  userRole: {
    fontSize: '1rem',
    color: '#9487ED',
  },
  userAvatar: {
    width: '4rem',
    height: '4rem',
  },
  testimonial: {
    padding: '0',
  },
  testimonialCopy: {
    fontSize: '1.25rem',
    lineHeight: '135%',
    color: '#fff',
  },
}));
