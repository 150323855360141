import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import exampleAvatar from '../assets/avatar-manuel_hauptmann.png';
import { useUserCardStyles } from '../styles/user-card.styles';

interface UserCardProps {
  userTestimonial: {
    name: string;
    role: string;
    testimonial: string;
    avatar: string;
  };
}

const UserCard = (props: UserCardProps) => {
  const {
    userTestimonial: { name, role, testimonial, avatar },
  } = props;
  const classes = useUserCardStyles();
  return (
    <Card className={classes.cardContainer}>
      <CardContent className={classes.user}>
        <CardMedia image={avatar} className={classes.userAvatar} />
        <Box className={classes.userInfo}>
          <Typography className={classes.userName}>{name}</Typography>
          <Typography className={classes.userRole}>{role}</Typography>
        </Box>
      </CardContent>
      <CardContent className={classes.testimonial}>
        <Typography className={classes.testimonialCopy}>
          {testimonial}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default UserCard;
