import { makeStyles } from '@mui/styles';

export const useLinkButtonStyles = makeStyles(() => ({
  ctaLink: {
    backgroundColor: 'transparent',
    color: '#fff',
    border: '2px solid #1EEF97',
    padding: '1rem 2rem',
    borderRadius: '62.5rem',
    '&:hover': {
      textDecoration: 'none',
    },
    textWrap: 'nowrap',
  },
  solid: {
    backgroundColor: '#1EEF97',
    color: '#1D0638',
    border: 'none',
  },
}));
