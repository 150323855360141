import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLabeledSwitchStyles } from './labeled-switch.styles';

interface LabeledSwitchProps {
  options: {
    firstOption: string;
    secondOption: string;
  };
  sx?: {
    [key: string]: string;
  };
  onSwitch: () => void;
}

const LabeledSwitch = (props: LabeledSwitchProps) => {
  const { options, sx, onSwitch } = props;
  const classes = useLabeledSwitchStyles();

  const [switched, setSwitched] = useState(false);

  const handleSwitch = () => {
    setSwitched(current => !current);
    onSwitch();
  };

  const handleInactiveClick = () => {
    handleSwitch();
  };

  const handleActiveClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <Box className={classes.switchContainer} sx={sx ? { ...sx } : {}}>
      <Box className={classes.optionsContainer}>
        <Box
          className={classes.optionBox}
          sx={switched ? { cursor: 'pointer' } : { cursor: 'default' }}
          onClick={switched ? handleInactiveClick : handleActiveClick}
        >
          <Typography
            className={classes.optionLabel}
            sx={{
              ...(switched ? { cursor: 'pointer' } : { cursor: 'default' }),
              marginLeft: '1.5rem',
            }}
          >
            {options.firstOption}
          </Typography>
        </Box>
        <Box
          className={classes.optionBox}
          onClick={switched ? handleActiveClick : handleInactiveClick}
          sx={!switched ? { cursor: 'pointer' } : { cursor: 'default' }}
        >
          <Typography
            className={classes.optionLabel}
            sx={{
              ...(!switched ? { cursor: 'pointer' } : { cursor: 'default' }),
              marginRight: '2rem',
            }}
          >
            {options.secondOption}
          </Typography>
        </Box>
      </Box>
      <Button
        className={` ${classes.switch} ${switched ? classes.switched : ''}`}
      />
    </Box>
  );
};

export default LabeledSwitch;
