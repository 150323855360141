import React, { useState, useEffect } from 'react';
import { SignInPageProps } from '@backstage/core-app-api';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import PolicyIcon from '@mui/icons-material/Policy';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import CookieIcon from '@mui/icons-material/Cookie';
import { useApi } from '@backstage/core-plugin-api';
import {
  Progress,
  SignInProviderConfig,
  UserIdentity,
} from '@backstage/core-components';
import { CariadXelerateSignInLogo } from './images/CariadXelerateSignInLogo';
import { useSignInPageStyles } from './signin-page.styles';

type SingleSignInPageProps = SignInPageProps & {
  provider: SignInProviderConfig;
  auto?: boolean;
};

type signinOpts = { showPopup: boolean };

// * Custom sign-in page component
const SignInPage: React.FC<SingleSignInPageProps> = ({
  provider,
  auto,
  onSignInSuccess,
}) => {
  const classes = useSignInPageStyles();
  const authApi = useApi(provider.apiRef);

  const [error, setError] = useState<Error>();
  const [showLoginPage, setShowLoginPage] = useState<boolean>(false);
  const [imageURLToUse, setImageURLToUse] = useState<URL | null>(null);

  // * Media queries for breakpoints
  const isSmallScreen = useMediaQuery('(max-width: 1379px)');
  const isMediumScreen = useMediaQuery(
    '(max-width: 2579px) and (min-width: 1380px)',
  );
  const isLargeScreen = useMediaQuery('(min-width: 2580px)');

  // * Set up responsive background images. Large is the default
  useEffect(() => {
    if (isSmallScreen) {
      setImageURLToUse(
        new URL(
          '../../../public/xelerate-gradient-bg-full-small.webp',
          import.meta.url,
        ),
      );
    } else if (isMediumScreen) {
      setImageURLToUse(
        new URL(
          '../../../public/xelerate-gradient-bg-full.webp',
          import.meta.url,
        ),
      );
    } else {
      setImageURLToUse(
        new URL(
          '../../../public/xelerate-gradient-bg-full-large.webp',
          import.meta.url,
        ),
      );
    }
  }, [isSmallScreen, isMediumScreen, isLargeScreen]);

  // * Handler for signin process, user initiated or automatic.
  const handleSignin = React.useCallback(
    async ({ showPopup }: signinOpts) => {
      try {
        let identityResponse;
        // * Only if it is user initiated does the popup show
        if (showPopup) {
          identityResponse = await authApi.getBackstageIdentity({
            instantPopup: true,
          });
        } else {
          identityResponse = await authApi.getBackstageIdentity({
            optional: true,
          });
        }
        if (!identityResponse) {
          setShowLoginPage(true);
          return;
        }
        const profile = await authApi.getProfile();
        onSignInSuccess(
          UserIdentity.create({
            identity: identityResponse.identity,
            authApi,
            profile,
          }),
        );
      } catch (err: any) {
        setError(err);
        setShowLoginPage(true);
      }
    },
    [authApi, onSignInSuccess],
  );

  // * Do an initial check if to see if any logged-in session exists.
  // * If it does, SignIn Page isn't rendered, and the user is taken directly to home page.
  useEffect(() => {
    if (auto) {
      handleSignin({ showPopup: false });
    }
  }, [handleSignin, auto]);

  return showLoginPage ? (
    <ScopedCssBaseline
      className={classes.base}
      style={{
        ...(imageURLToUse ? { backgroundImage: `url(${imageURLToUse})` } : {}),
      }}
    >
      <article className={classes.mainWrapper}>
        <header className={classes.cariadLogoWrapper}>
          <CariadXelerateSignInLogo />
        </header>
        <section className={classes.formWrapper}>
          <Typography variant="h2" className={classes.subtitle}>
            Power to the developers, makers, and thinkers
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              handleSignin({ showPopup: true });
            }}
            className={classes.button}
            sx={{
              fontSize: {
                md: '1.25rem',
                lg: '1.5rem',
              },
            }}
          >
            {provider.message}
          </Button>
          {error && error.name !== 'PopupRejectedError' && (
            <Typography variant="body1" color="#EE5340">
              {`Something went wrong during your authentication: ${error.message}`}
            </Typography>
          )}
        </section>
      </article>
      <Box className={classes.linksContainer}>
        <Link
          href="https://cariad.technology/de/en/legal-notice.html"
          variant="body1"
          className={classes.link}
          underline="none"
          target="_blank"
        >
          <PolicyIcon style={{ fontSize: 30 }} />
          Legal Notice
        </Link>
        <Link
          href="/CARIAD-privacy-policy-en.pdf"
          variant="body1"
          className={classes.link}
          underline="none"
          target="_blank"
        >
          <LockPersonIcon style={{ fontSize: 30 }} />
          Privacy Policy
        </Link>
        <Link
          href="/CARIAD-cookie-policy-en.pdf"
          variant="body1"
          className={classes.link}
          underline="none"
          target="_blank"
        >
          <CookieIcon style={{ fontSize: 30 }} />
          Cookie Policy
        </Link>
      </Box>
    </ScopedCssBaseline>
  ) : (
    <Progress />
  );
};

export default SignInPage;
