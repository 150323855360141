import React from 'react';
import {
  SettingsLayout,
  UserSettingsProfileCard,
  UserSettingsAppearanceCard,
  UserSettingsIdentityCard,
  UserSettingsAuthProviders,
} from '@backstage/plugin-user-settings';
import Grid from '@mui/material/Grid';
import { ProfileUserDeleteCard } from '@internal/backstage-plugin-profile-user-delete';

export const settingsPage = (
  <SettingsLayout>
    <SettingsLayout.Route path="general" title="General">
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} md={6}>
          <UserSettingsProfileCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <UserSettingsAppearanceCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <UserSettingsIdentityCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProfileUserDeleteCard />
        </Grid>
      </Grid>
    </SettingsLayout.Route>
    <SettingsLayout.Route path="providers" title="Authentication Providers">
      <UserSettingsAuthProviders />
    </SettingsLayout.Route>
    {/* <SettingsLayout.Route path="feature-flags" title="Feature Flags">
      <UserSettingsFeatureFlags />
    </SettingsLayout.Route> */}
  </SettingsLayout>
);
