import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Link } from '@backstage/core-components';
import CardMedia from '@mui/material/CardMedia';
import LabeledSwitch from '../../reusable/labeled-switch/LabeledSwitch';
import { useFeatureCardStyles } from '../styles/feature-card.styles';

interface FeatureCardProps {
  content: {
    FEATURE: string;
    TITLE: string;
    COPY_CONSUMERS: string;
    COPY_PUBLISHERS: string;
    CTA: string;
    CTA_HREF: string;
    IMAGE: string;
  };
  optionSwitch?: boolean;
  inverted?: boolean;
}

const switchOptions = {
  firstOption: 'For Consumers',
  secondOption: 'For Publishers',
};

const FeatureCard = (props: FeatureCardProps) => {
  const {
    content: {
      FEATURE,
      TITLE,
      COPY_CONSUMERS,
      COPY_PUBLISHERS,
      CTA,
      CTA_HREF,
      IMAGE,
    },
    optionSwitch,
    inverted,
  } = props;

  const classes = useFeatureCardStyles();

  const [copy, setCopy] = useState(COPY_CONSUMERS);

  const handleSwitchCopy = () => {
    setCopy(current =>
      current === COPY_CONSUMERS ? COPY_PUBLISHERS : COPY_CONSUMERS,
    );
  };

  return (
    <Container
      className={`${classes.featureContainer} ${inverted ? classes.inverted : ''}`}
    >
      <Box
        className={`${classes.featureContent} ${inverted ? classes.right : classes.left}`}
      >
        <Typography className={classes.featureCategory}>{FEATURE}</Typography>
        <Typography className={classes.featureTitle}>{TITLE}</Typography>
        {optionSwitch && (
          <LabeledSwitch
            options={switchOptions}
            onSwitch={handleSwitchCopy}
            sx={{ marginBottom: '2.5rem' }}
          />
        )}
        <Typography className={classes.featureCopy}>{copy}</Typography>
        <Link to={CTA_HREF} className={classes.featureCta}>
          {CTA}
        </Link>
      </Box>
      <CardMedia
        component="img"
        image={IMAGE}
        className={`${classes.featureImage} ${inverted ? classes.left : classes.right}`}
      />
    </Container>
  );
};

export default FeatureCard;
