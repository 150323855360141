import React, { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import UserCard from './UserCard';
import { useTestimonialsStyles } from '../styles/testimonials.styles';

interface TestimonialsProps {
  userTestimonials: Array<{
    name: string;
    role: string;
    testimonial: string;
    avatar: string;
  }>;
}

const Testimonials = (props: TestimonialsProps) => {
  const { userTestimonials } = props;
  const classes = useTestimonialsStyles();
  const carouselRef = React.useRef<HTMLDivElement | null>(null);
  const [currentPage, setCurrentPage] = useState(0);

  // Note: 1504 is the breakpoint 1440px plus 32px padding on each side of the page container that should be respected.
  const isBelowFirstBreakpoint = useMediaQuery('(max-width: 1504px)');
  const isBelowSecondBreakpoint = useMediaQuery('(max-width: 1400px)');
  const cardWidth = 400;
  const cardsPerPage = !isBelowSecondBreakpoint ? 3 : 2;
  const slideAmount = !isBelowSecondBreakpoint
    ? cardWidth + 96
    : cardWidth - 64;
  const isLastPage = !isBelowSecondBreakpoint
    ? currentPage > Math.ceil(userTestimonials.length / cardsPerPage)
    : currentPage >=
      Math.ceil(userTestimonials.length / cardsPerPage) + cardsPerPage + 1;
  const isFirstPage = currentPage === 0;
  const containerWidth = cardWidth * cardsPerPage - 50;
  const showLeftGradient = !isFirstPage;
  const showRightGradient = !isLastPage;

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -slideAmount, behavior: 'smooth' });
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: slideAmount, behavior: 'smooth' });
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const setDisabled = (scrollDirection: 'left' | 'right'): boolean => {
    if (scrollDirection === 'left') {
      return isFirstPage;
    } else if (scrollDirection === 'right') {
      return isLastPage;
    }
    return false;
  };

  return (
    <Box
      sx={
        isBelowFirstBreakpoint
          ? { maxWidth: '71rem', marginLeft: '3rem', marginRight: '1rem' }
          : {}
      }
    >
      <Box className={classes.topContainer}>
        <Typography className={classes.title}>Our users say</Typography>
        <Box className={classes.buttonsContainer}>
          <IconButton
            className={classes.iconButton}
            onClick={scrollLeft}
            sx={{ margin: 5 }}
            disabled={setDisabled('left')}
          >
            <ArrowBackIcon className={`${classes.arrowIcon}`} />
          </IconButton>
          <IconButton
            className={classes.iconButton}
            onClick={scrollRight}
            sx={{ margin: 5 }}
            disabled={setDisabled('right')}
          >
            <ArrowForwardIcon className={`${classes.arrowIcon}`} />
          </IconButton>
        </Box>
      </Box>
      <Box
        className={classes.testContainer}
        sx={{ width: `${containerWidth + 10}px` }}
      >
        {showLeftGradient && (
          <div className={`${classes.gradient} ${classes.gradientLeft}`} />
        )}
        <Box
          component="div"
          ref={carouselRef}
          className={classes.cardsContainer}
          sx={{ width: `${containerWidth}px` }}
        >
          {userTestimonials.map(test => (
            <UserCard userTestimonial={test} />
          ))}
        </Box>
        {showRightGradient && (
          <div className={`${classes.gradient} ${classes.gradientRight}`} />
        )}
      </Box>
    </Box>
  );
};

export default Testimonials;
