import { makeStyles } from '@mui/styles';

// Uncomment theme if you need to access the theme's values
export const useAboutPageStyles = makeStyles((/* theme */) => ({
  heading1: {
    fontFamily: 'FKCARIAD-Light',
    fontSize: '4rem',
    textAlign: 'center',
    color: '#fff',
  },
  heading3: {
    fontFamily: 'FKCARIAD-Light',
    fontSize: '2rem',
    textAlign: 'center',
    color: '#fff',
  },
  body: {
    fontSize: '1.5rem',
    fontFamily: 'FKCARIAD-Light',
    color: '#fff',
  },
  topContainer: {
    backgroundColor: '#1D0638',
  },
  layoutContainer: {
    maxWidth: '100%',
    margin: '0',
    padding: '0 2rem',
  },
  landingSection: {
    paddingTop: '5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    zIndex: '1',
  },
  bgGradient: {
    position: 'absolute',
    zIndex: '-1',
    width: '1151px',
  },
  landingCopy: {
    paddingTop: '1.25rem',
    paddingBottom: '2.5rem',
    maxWidth: '59.375rem',
    textAlign: 'center',
    fontFamily: 'FKCARIAD-Light',
  },
  ctaLinks: {
    display: 'flex',
    gap: '1rem',
  },
  screenshotContainer: {
    paddingTop: '5rem',
  },
  featuresSection: {},
  testimonialsSection: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5rem',
    marginLeft: '-50px',
  },
  footerSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  linksSection: {
    display: 'flex',
    justifyContent: 'center',
  },
  linksContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    padding: '5rem',
  },
  link: {
    fontSize: '1rem',
    color: '#1eef97',
  },
}));
