import { Paper, Typography } from '@material-ui/core';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { usePopupMenuStyles } from './popup-menu.styles';

interface PopupMenuOptions {
  label: string;
  external: boolean;
  to: string;
  icon: () => React.JSX.Element;
}

interface PopupMenuProps {
  options: Array<PopupMenuOptions>;
  open: boolean;
  anchorEl?: Element;
  ref?: React.Ref<any>;
  onClickAway?: (event?: MouseEvent | TouchEvent) => void;
  onEnter?: (event?: React.PointerEvent<HTMLElement>) => void;
  onLeave: (event?: React.PointerEvent<HTMLElement>) => void;
}

function PopupMenu({
  options,
  open,
  anchorEl,
  onClickAway,
  onLeave,
  onEnter,
  ref,
}: PopupMenuProps) {
  const canBeOpen = open && !!anchorEl;
  const id = canBeOpen ? 'spring-popper' : undefined;
  const classes = usePopupMenuStyles();

  // Close the popup when pointer leaves the popup or anchor element
  const pointerLeaveHandler = (event: React.PointerEvent<HTMLElement>) => {
    // this is true if the pointer is moving from the popup to the anchor element
    // in this case we don't want to close the popup
    if (anchorEl?.contains(event.relatedTarget as Node)) {
      return;
    }
    onLeave(event);
  };

  return (
    <Popper
      ref={ref}
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="right-start"
      onPointerEnter={onEnter}
      onPointerLeave={pointerLeaveHandler}
      className={classes.popperRoot}
    >
      <Paper className={classes.root}>
        <Typography className={classes.title}>Support</Typography>
        <ClickAwayListener onClickAway={event => open && onClickAway?.(event)}>
          <MenuList className={classes.menuList}>
            {options.map(option => {
              if (option.external) {
                return (
                  <MenuItem onClick={() => onLeave()}>
                    {option.icon()}
                    <Link
                      href={option.to}
                      target="_blank"
                      className={classes.link}
                      underline="none"
                    >
                      {option.label}
                    </Link>
                  </MenuItem>
                );
              }
              return (
                <MenuItem onClick={() => onLeave()}>
                  {option.icon()}
                  <Link
                    component={NavLink}
                    to={option.to}
                    className={classes.link}
                    underline="none"
                  >
                    {option.label}
                  </Link>
                </MenuItem>
              );
            })}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
}

export default PopupMenu;
