import {
  createPermission,
  ResourcePermission,
} from '@backstage/plugin-permission-common';
import { RESOURCE_TYPE_CATALOG_ENTITY } from '@backstage/plugin-catalog-common/alpha';

export const limitedAccessPermission: ResourcePermission<'catalog-entity'> =
  createPermission({
    name: 'limited-access-permission',
    attributes: { action: 'read' },
    resourceType: RESOURCE_TYPE_CATALOG_ENTITY,
  });

export const accessPermissions = [limitedAccessPermission];

/*
 * For this permission to work
 * 1. Make it available in the catalog-backend-module-group-entity-processor
 * 2. Create a Rule that validates the permission in the catalog-backend-module-group-entity-processor
 */
