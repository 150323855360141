import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import LinkButton from '../link-button/LinkButton';
import { useCallToActionCardStyles } from './call-to-action-card.styles';

interface CallToActionCardProps {
  title: string;
  subtitle: string | ReactNode;
  ctas: Array<{
    ctaText: string;
    to: string;
    variant: 'primary' | 'secondary';
  }>;
  gradient: boolean;
  cardPadding: string;
  fullWidth?: boolean;
}

const CallToActionCard = (props: CallToActionCardProps) => {
  const classes = useCallToActionCardStyles();
  const { title, subtitle, ctas, gradient, cardPadding, fullWidth } = props;
  const CardContainer: React.ComponentType<any> = fullWidth ? Box : Container;
  return (
    <CardContainer
      className={
        gradient
          ? `${classes.ctaCard} ${classes.ctaCard}__gradient`
          : classes.ctaCard
      }
      style={{ padding: cardPadding }}
    >
      <Typography className={`${classes.heading1} ${classes.ctaTitle}`}>
        {title}
      </Typography>
      <Typography
        className={`${classes.heading3} ${classes.ctaCopy}`}
        style={{ marginBottom: '2.5rem' }}
      >
        {subtitle}
      </Typography>
      <Box className={classes.ctaLinks}>
        {ctas.map(cta => (
          <LinkButton
            to={cta.to}
            label={cta.ctaText}
            solid={cta.variant === 'primary'}
          />
        ))}
      </Box>
    </CardContainer>
  );
};

export default CallToActionCard;
