import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { InfoCard, Header, Page, Content } from '@backstage/core-components';
import { usePolicyPageStyles } from './styles/policy-page.styles';

const PrivacyPolicyPage = () => {
  const classes = usePolicyPageStyles();
  return (
    <Page themeId="documentation">
      <Header title="Privacy Policy" />
      <Content>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <InfoCard
              divider={false}
              className={classes.infoCard}
              // title="Privacy Policy for the Internal Developer Portal 'XELERATE'"
            >
              <Typography variant="h4" className={classes.heading}>
                Privacy Policy for the Internal Developer Portal 'XELERATE'
              </Typography>
              <Typography variant="h5" className={classes.heading}>
                1. Person in charge
              </Typography>
              <Typography
                className={classes.body}
                style={{ display: 'inline' }}
                variant="body1"
              >
                With this privacy policy, we inform you about the processing of
                your personal data by CARIAD SE, Berliner Ring 2, 38440
                Wolfsburg, for our internal developer portal "XELERATE". You can
                reach our data protection officer by post with the addition -
                data protection officer - or by e-mail at&nbsp;
              </Typography>
              <a
                href="mailto:privacy@cariad.technology"
                target="_blank"
                className={classes.link}
              >
                privacy@cariad.technology
              </a>
              <Typography variant="body1" className={classes.body}>
                You can find more information about employee data protection at
                CARIAD on the intranet. For project partners of CARIAD SE, you
                can find more information on the CARIAD website:
              </Typography>
              <a
                href="https://cariad.technology/de/en/privacy-policy.html"
                target="_blank"
                className={classes.link}
              >
                https://cariad.technology/de/en/privacy-policy.html
              </a>
              <Typography variant="h5" className={classes.heading}>
                2. Collection and processing of personal data
              </Typography>
              <Typography variant="h6" className={classes.title}>
                a&#41; Purpose
              </Typography>
              <Typography variant="body1" className={classes.body}>
                CARIAD SE operates an internal developer portal called
                "XELERATE" (available at: https://developer.cariad.digital). In
                order to be able to use the Developer Portal, we process
                personal data for the following purposes:
              </Typography>
              <ul className={classes.list}>
                <li>authentication and authorization of users;</li>
                <li>
                  Improvement and analysis of the Developer Portal for daily
                  work at CARIAD;
                </li>
                <li>
                  Providing information on updates, maintenance and important
                  changes;
                </li>
                <li>Ensuring certain necessary functions;</li>
                <li>Optimising system stability.</li>
              </ul>
              <Typography variant="h6" className={classes.title}>
                b&#41; Categories
              </Typography>
              <Typography variant="body1" className={classes.body}>
                In order to fulfil the aforementioned purpose, CARIAD SE
                processes the following personal data about you:
              </Typography>
              <ul className={classes.list}>
                <li>first and last name;</li>
                <li>E-mail address CARIAD employees;</li>
                <li>
                  IT usage data (IT log data / log files):
                  <ul>
                    <li>Browser type and version</li>
                    <li>IP address </li>
                    <li>Operating system</li>
                    <li>Domain</li>
                    <li>Page visits: date and time, duration of the visit</li>
                  </ul>
                </li>
              </ul>
              <Typography variant="body1" className={classes.body}>
                In addition, a tool hosted by CARIAD SE itself ("Matomo
                Analytics") is used to anonymize and evaluate anonymous usage
                statistics to improve the Developer Portal (anonymized data over
                the duration of a session, weekly or monthly active users, use
                of features, page visits, tracking of paths to determine break
                points, etc.) - see Cookie Policy.
              </Typography>
              <Typography variant="h6" className={classes.title}>
                c&#41; Legal bases
              </Typography>
              <Typography variant="body1" className={classes.body}>
                The processing of the above-mentioned categories of data is
                carried out on the basis of the following legal bases:
              </Typography>
              <ul className={classes.list}>
                <li>
                  Section 26 (1) sentence 1 BDSG in conjunction with Article 6
                  (1) (b) GDPR - Implementation of the employment relationship
                  and improvement of the digital working environment;
                </li>
                <li>
                  Article 6 (1) (f) GDPR, legitimate interests with regard to
                  project partners, employees and improvement of digital working
                  conditions.
                </li>
              </ul>
              <Typography variant="h6" className={classes.title}>
                d&#41; Recipient categories
              </Typography>
              <Typography variant="body1" className={classes.body}>
                Your data will be disclosed to the following data recipients for
                the purpose defined above:
              </Typography>
              <ul className={classes.list}>
                <li>Processor of CARIAD SE;</li>
                <li>
                  Companies of the Volkswagen Group (first and last name and
                  e-mail address).
                </li>
              </ul>
              <Typography variant="body1" className={classes.body}>
                There will be no other disclosure of personal data to third
                parties in this context.
              </Typography>
              <Typography variant="h6" className={classes.title}>
                e&#41; Transfer to third countries
              </Typography>
              <Typography variant="body1" className={classes.body}>
                CARIAD SE does not transfer data to countries outside the
                EU/EEA.
              </Typography>
              <Typography variant="h6" className={classes.title}>
                f&#41; Storage period
              </Typography>
              <ul className={classes.list}>
                <li>Account data as long as the account is active.</li>
                <li>
                  IT usage data: 30 days for security and auditing purposes.
                </li>
              </ul>
              <Typography variant="h5" className={classes.heading}>
                3. Your rights
              </Typography>
              <Typography variant="body1" className={classes.body}>
                In addition to the right to information about the data
                concerning you and the correction of your data, you also have
                the right to erasure as well as a right to object to the
                processing or the right to restrict the processing of your data,
                provided that there are no legal regulations to the contrary.
                You also have the right to data portability. If we collect and
                process your personal data on the basis of your consent, you
                also have the right to revoke the consent you have given with
                effect for the future. If necessary, we will need to verify your
                identity before we can process your request. If, despite our
                efforts to ensure that the data is correct and up-to-date,
                incorrect information is stored, we will correct it after
                notifying us accordingly. In the event of complaints, it is
                possible to contact a data protection supervisory authority.
              </Typography>
              <Typography variant="h5" className={classes.heading}>
                4. Automated decision-making
              </Typography>
              <Typography variant="body1" className={classes.body}>
                There are no automated decision-making processes in accordance
                with Art. 22 para. 1, 4 GDPR.
              </Typography>
              <Typography variant="h5" className={classes.heading}>
                5. Data integrity
              </Typography>
              <Typography variant="body1" className={classes.body}>
                Your data is protected by CARIAD by technical and organizational
                security measures to prevent accidental or intentional
                manipulation, loss, destruction or access by unauthorized
                persons.
              </Typography>
              <Typography variant="body1" className={classes.body}>
                Our security measures, such as data encryption, are regularly
                improved in line with technological developments.
              </Typography>
              <Typography
                variant="body1"
                className={classes.body}
                style={{ marginTop: '2rem' }}
              >
                As of: August 2024
              </Typography>
            </InfoCard>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};

export default PrivacyPolicyPage;
