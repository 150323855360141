import { makeStyles } from '@mui/styles';

export const useTestimonialsStyles = makeStyles(() => ({
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2.5rem',
  },
  title: {
    fontSize: '3rem',
    color: '#fff',
  },
  buttonsContainer: {
    display: 'flex',
    gap: '1.5rem',
    marginRight: '4rem',
  },
  iconButton: {
    margin: '0',
    borderRadius: '10000px',
    border: '2px solid #9487ED',
    color: '#fff',
    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.5)',
    },
  },
  arrowIcon: {
    fontSize: '2rem',
  },
  arrowDisabled: {
    color: '#fff',
    opacity: '0.5',
  },
  testContainer: {
    position: 'relative',
  },
  cardsContainer: {
    position: 'relative',
    display: 'flex',
    gap: '2rem',
    overflowX: 'hidden',
    paddingLeft: '5px',
  },
  gradient: {
    position: 'absolute',
    width: '25rem',
    height: '100%',
    zIndex: 10,
    boxSizing: 'border-box',
    top: '0',
  },
  gradientLeft: {
    background:
      'linear-gradient(90deg, rgba(29,6,56,1) 0%, rgba(29,6,56,1) 10%, rgba(0,0,0,0) 100%)',
    transform: 'translateX(-10px)',
    left: '0',
  },
  gradientRight: {
    background:
      'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(29,6,56,1) 90%, rgba(29,6,56,1) 100%)',
    right: '0',
  },
}));
