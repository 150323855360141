import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { InfoCard, Header, Page, Content } from '@backstage/core-components';
import { usePolicyPageStyles } from './styles/policy-page.styles';

const CookiePolicyPage = () => {
  const classes = usePolicyPageStyles();
  return (
    <Page themeId="documentation">
      <Header title="Cookie Policy" />
      <Content>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <InfoCard
              divider={false}
              className={classes.infoCard}
              // title="Cookie Policy"
            >
              <Typography variant="h5" className={classes.heading}>
                Introduction
              </Typography>
              <Typography
                className={classes.body}
                style={{ display: 'inline' }}
                variant="body1"
              >
                Our website uses cookies to enhance your browsing experience and
                to analyze our website traffic. This Cookie Policy explains how
                we use cookies and other tracking technologies (collectively
                referred to as “tools”) on our website.
              </Typography>
              <Typography variant="h5" className={classes.heading}>
                What are Cookies?
              </Typography>
              <Typography variant="body1" className={classes.body}>
                Cookies are small data files stored on your device that contain
                information about your site settings and log-in information.
                These cookies are sent by the web server to your browser and
                stored on your device.
              </Typography>
              <Typography variant="h5" className={classes.heading}>
                Types of Cookies We Use
              </Typography>
              <Typography variant="h6" className={classes.heading}>
                1. Session Cookies
              </Typography>
              <ul>
                <li>
                  <Typography variant="body1" className={classes.heading}>
                    <strong>Purpose:</strong>These cookies are essential for
                    maintaining user sessions on the browser, ensuring that you
                    remain logged in as you navigate through our website.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" className={classes.heading}>
                    <strong>Lifespan: </strong>Session (deleted when you close
                    your browser).
                  </Typography>
                </li>
              </ul>
              <Typography variant="h6" className={classes.heading}>
                2. Analytics Cookies
              </Typography>
              <ul>
                <li>
                  <Typography variant="body1" className={classes.heading}>
                    <strong>Purpose:</strong>We use the Backstage Analytics API
                    integrated with Matomo to analyze user behavior and improve
                    our website's functionality and user experience. These
                    cookies help us understand how visitors interact with our
                    website, allowing us to make data-driven improvements. User
                    identities are masked, and we do not track individual user
                    identity through Matomo Analytics.
                  </Typography>
                </li>
              </ul>
              <Typography variant="subtitle1" className={classes.subtitle}>
                Typical Matomo Cookies:
              </Typography>
              <ul>
                <li>
                  <Typography variant="body1" className={classes.heading}>
                    <strong>_pk_id.X.Y: </strong>Stores a unique visitor ID.
                    Lifespan: 13 months.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" className={classes.heading}>
                    <strong>_pk_ses.X.Y: </strong>Temporarily stores visit data.
                    Lifespan: 30 minutes.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" className={classes.heading}>
                    <strong>_pk_ref.X.Y: </strong>Stores referrer information.
                    Lifespan: 6 months.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" className={classes.heading}>
                    <strong>_pk_cvar.X.Y: </strong>Temporarily stores custom
                    variables data. Lifespan: 30 minutes.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" className={classes.heading}>
                    <strong>_pk_hsr.X.Y: </strong>Temporarily stores heatmap
                    session data. Lifespan: 30 minutes.
                  </Typography>
                </li>
              </ul>
              <Typography variant="h5" className={classes.heading}>
                Data Storage and Usage
              </Typography>
              <Typography variant="h6" className={classes.heading}>
                User Information
              </Typography>
              <Typography variant="body1" className={classes.heading}>
                We utilize federation with Cloud IDP to manage user sign-in,
                providing a secure and seamless authentication process. we
                securely store your username and email in our database to ensure
                a personalized user experience. All information is managed in
                strict compliance with data protection regulations, safeguarding
                your privacy and security. No other personal data is stored.
              </Typography>
              <Typography variant="h6" className={classes.heading}>
                Managing Your Cookie Preferences
              </Typography>
              <Typography variant="body1" className={classes.heading}>
                You can manage your cookie preferences through your browser
                settings. Most browsers allow you to:
              </Typography>
              <ul>
                <li>
                  <Typography variant="body1" className={classes.heading}>
                    View what cookies are stored on your device and delete them.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" className={classes.heading}>
                    Block third-party cookies.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" className={classes.heading}>
                    Block cookies from specific websites.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" className={classes.heading}>
                    Block all cookies from being set.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" className={classes.heading}>
                    Delete all cookies when you close your browser.
                  </Typography>
                </li>
              </ul>
              <Typography variant="h5" className={classes.heading}>
                Data Storage and Usage
              </Typography>
              <Typography variant="body1" className={classes.heading}>
                We may update this Cookie Policy from time to time to reflect
                changes in our practices or for other operational, legal, or
                regulatory reasons. We will notify you of any changes by posting
                the new Cookie Policy on our website.
              </Typography>
              <Typography variant="h5" className={classes.heading}>
                Contact Us
              </Typography>
              <Typography variant="body1" className={classes.heading}>
                If you have any questions or concerns about our use of cookies
                and other tracking technologies, please contact us at:
              </Typography>
              <ul>
                <li>
                  <Typography variant="body1" className={classes.heading}>
                    <strong>Email: </strong>xelerate@cariad.technology
                  </Typography>
                </li>
              </ul>
            </InfoCard>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};

export default CookiePolicyPage;
