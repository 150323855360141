export const userTestimonials = [
  {
    name: 'Manuel Hauptmann',
    role: 'API Management Expert',
    testimonial:
      'XELERATE provides a comprehensive API catalog of the CARIAD landscape, which improves the discoverability of our capabilities. The service documentation consolidates essential information, making it simple for everyone to find what they need.',
    avatar: require('../assets/avatar-manuel-hauptmann.png'),
  },
  {
    name: 'André Hacke',
    role: 'Product Manager, Megatron',
    testimonial:
      'For our platform engineering team, the capability to develop custom plugins tailored to our specific needs has optimized our workflows and enables us to deliver significant advantages to our platform users.',
    avatar: require('../assets/avatar-andre_hacke.png'),
  },
  {
    name: 'Yannick Foaleng-Mokiedje',
    role: 'Head of Cloud Stability & Enablers',
    testimonial:
      'Success in a software-driven company requires anticipating customer demand and accelerating development processes while reducing costs. At CARIAD, XELERATE plays a central role in this by reducing developer onboarding efforts and providing a central platform for all necessary assets, fostering alignment and innovation.',
    avatar: require('../assets/avatar-yannick-foaleng.png'),
  },
  {
    name: 'Andreas Nürenberg',
    role: 'Solution Architect, Cloud Platform',
    testimonial:
      'XELERATE improves product team onboarding and the developer experience by enhancing API management and streamlining development processes across our cloud and device platforms.',
    avatar: require('../assets/avatar-andreas-nurember.png'),
  },
  {
    name: 'Omar Ekine',
    role: 'Solution Manager',
    testimonial:
      'It’s very easy to upload API Specs and Techdocs into XELERATE. The feedback from internal teams I have presented XELERATE to has also been very positive. Hence, our planned ecosystem-enabling 3rd party developer platform will be based on it!',
    avatar: require('../assets/avatar-omar-ekine.png'),
  },
];
