import React, { PropsWithChildren, useRef, useState } from 'react';
import {
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
} from '@backstage/core-components';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { usePermission } from '@backstage/plugin-permission-react';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import CategoryIcon from '@mui/icons-material/Category';
import ExtensionIcon from '@mui/icons-material/Extension';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import SidebarLogo from './SideBarLogo';
import SidebarSupportSubmenu from './SidebarSupportSubmenu';
/* import { XelerateLogoIconMono } from '../Icons/icons'; */

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const { loading: loadingPermission, allowed: canCreateEntity } =
    usePermission({
      permission: catalogEntityCreatePermission,
    });
  const supportRef = useRef<HTMLElement>();
  const supportPopupRef = useRef<HTMLElement>();
  const [supportPopupOpen, setSupportPopupOpen] = useState(false);
  const [isSupportHoveredOn, setIsSupportHoveredOn] = useState(false);

  // Close the popup when pointer leaves the popup or anchor element
  const handlePointerLeave = (event: PointerEvent) => {
    // this is true if the pointer is moving from the sidebar to the popup
    // in this case we don't want to close the popup
    if (supportPopupRef.current?.contains(event.relatedTarget as Node)) {
      return;
    }
    setSupportPopupOpen(false);
  };

  // handles the opening of the popup
  // if the sidebar is closed, the popup will open after the animation ends
  // if the sidebar is open, the popup will open immediately
  const handleAnimation = (elem: HTMLElement | null) => {
    if (!elem) return;

    // check if the sidebar is closed...
    const drawerClosed = elem.className.includes('BackstageSidebarItem-closed');

    if (!drawerClosed) {
      setSupportPopupOpen(true);
      return;
    }

    // ...if it is, wait for the animation to end before opening the popup
    elem.addEventListener(
      'animationend',
      () => {
        // console.log(elem.getAnimations());
        // console.log(event);
        setSupportPopupOpen(true);
      },
      { once: true },
    );
  };

  // Open the popup when clicking the support button
  const handleClickSupport = (event: React.MouseEvent<Element, MouseEvent>) => {
    const elem = event.currentTarget as HTMLElement;
    elem.onpointerleave ||= handlePointerLeave;
    supportRef.current = elem;

    if (supportPopupOpen) {
      setSupportPopupOpen(false);
      setIsSupportHoveredOn(false);
    } else {
      handleAnimation(elem);
      setIsSupportHoveredOn(true);
    }
  };

  const handlePopupEnterSupport = (
    event?: React.PointerEvent<HTMLElement> | undefined,
  ) => {
    supportPopupRef.current = event?.target as HTMLElement;
    setIsSupportHoveredOn(true);
  };

  const handlePopupLeaveSupport = () => {
    setSupportPopupOpen(false);
    setIsSupportHoveredOn(false);
  };

  const handlePopupClickAwaySupport = () => {
    setSupportPopupOpen(false);
    setIsSupportHoveredOn(false);
  };

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal />
        </SidebarGroup>
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {/* Global nav, not org-specific */}
          {/* Changing to icon={() => <IconCompoennt />} because of known issue with mui 5 upgrade.
          This is an aletrnative that solves it. Reference: https://github.com/backstage/backstage/issues/18018*/}
          <SidebarItem icon={() => <HomeIcon />} to="/" text="Home" />
          <SidebarItem
            icon={() => <CategoryIcon />}
            to="/catalog"
            text="Catalog"
          />
          <SidebarItem
            icon={() => <ExtensionIcon />}
            to="api-docs"
            text="APIs"
          />
          <SidebarItem icon={() => <LibraryBooks />} to="docs" text="Docs" />
          <SidebarItem
            icon={() => <LibraryBooks />}
            to="/create"
            text="Templates"
          />
          {!loadingPermission && canCreateEntity && (
            <SidebarItem
              icon={() => <CreateComponentIcon />}
              to="catalog-import"
              text="Add..."
            />
          )}
          {/* Original create button
          {!loadingPermission && canCreateEntity && (
            <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
          )}
        */}
          {/* End global nav */}
          <SidebarDivider />
          {/* Temporarily comment out new about page for August 21st release */}
          {/* <SidebarItem
            icon={() => <XelerateLogoIconMono />}
            to="/about"
            text="About Us"
          /> */}
          <SidebarItem
            icon={() => <AnnouncementIcon />}
            to="/announcements"
            text="Announcements"
          />
          <SidebarItem
            to="https://stackoverflow.cariad.digital/"
            target="_blank"
            icon={() => <GroupsIcon />}
            aria-label="Community"
            text="Community"
          />
          <SidebarSupportSubmenu
            isHoveredOn={isSupportHoveredOn}
            supportRef={supportRef}
            popupOpen={supportPopupOpen}
            onSetIsHoveredOn={setIsSupportHoveredOn}
            onHandleClick={handleClickSupport}
            onHandlePopupClickAway={handlePopupClickAwaySupport}
            onHandlePopupEnter={handlePopupEnterSupport}
            onHandlePopupLeave={handlePopupLeaveSupport}
          />
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
