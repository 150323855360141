import { makeStyles } from '@mui/styles';

// Uncomment theme if you need to access the theme's values
export const useFeatureCardStyles = makeStyles((/* theme */) => ({
  featureContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8.75rem',
    marginTop: '6.25rem',
    paddingInline: '0',
  },
  inverted: {},
  left: {
    order: 1,
  },
  right: {
    order: 2,
  },
  featureContent: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '35rem',
  },
  featureImage: {
    borderRadius: '1.5rem',
    maxHeight: '27rem',
    maxWidth: '38rem',
  },
  featureCategory: {
    fontSize: '.875rem',
    fontWeight: 'bold',
    color: '#9487ED',
    marginBottom: '1rem',
  },
  featureTitle: {
    fontSize: '2.25rem',
    marginBottom: '2.5rem',
    color: '#fff',
  },
  featureCopy: {
    color: '#fff',
    fontFamily: 'FKCARIAD-Light',
    fontSize: '1.25rem',
    lineHeight: '175%',
    marginBottom: '1.5rem',
  },
  featureCta: {
    fontSize: '1.25rem',
    color: '#1EEF97',
  },
}));
