export const aboutPageCopy = {
  footerTitle: "Unlock Your Team's Potential",
  footerSubtitle:
    'Get on the fast track with XELERATE! Streamline your onboarding, centralise all your resources, and ensure your users stay updated.',
};

interface FooterCta {
  ctaText: string;
  variant: 'primary' | 'secondary';
  to: string;
}

export const footerCtas: FooterCta[] = [
  {
    ctaText: 'Publish a Project',
    variant: 'primary',
    to: '/catalog-import',
  },
  {
    ctaText: 'Explore Catalog',
    variant: 'secondary',
    to: '/catalog',
  },
];
