import React from 'react';
import { Link } from '@backstage/core-components';
import { useLinkButtonStyles } from './link-button.styles';

interface LinkButtonProps {
  to: string;
  label: string;
  solid?: boolean;
}

const LinkButton = (props: LinkButtonProps) => {
  const { to, label, solid } = props;
  const classes = useLinkButtonStyles();
  return (
    <Link
      to={to}
      className={`${classes.ctaLink} ${solid ? classes.solid : ''}`}
    >
      {label}
    </Link>
  );
};

export default LinkButton;
