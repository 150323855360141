import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const profileUserDeletePlugin = createPlugin({
  id: 'profile-user-delete',
  routes: {
    root: rootRouteRef,
  },
});

// export const ProfileUserDeletePage = profileUserDeletePlugin.provide(
//   createRoutableExtension({
//     name: 'ProfileUserDeletePage',
//     component: () =>
//       import('./components/ExampleComponent').then(m => m.ExampleComponent),
//     mountPoint: rootRouteRef,
//   }),
// );

export const ProfileUserDeleteCard: () => JSX.Element | null =
  profileUserDeletePlugin.provide(
    createComponentExtension({
      name: 'ProfileUserDeleteCard',
      component: {
        lazy: () =>
          import('./components/DeleteProfile').then(m => m.DeleteProfileCard),
      },
    }),
  );
