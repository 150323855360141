import { makeStyles } from '@mui/styles';

export const useCallToActionCardStyles = makeStyles(() => ({
  heading1: {
    fontFamily: 'FKCARIAD-Light',
    fontSize: '4rem',
    textAlign: 'center',
    color: '#fff',
  },
  heading3: {
    fontFamily: 'FKCARIAD-Light',
    fontSize: '2rem',
    textAlign: 'center',
    color: '#fff',
  },
  ctaLinks: {
    display: 'flex',
    gap: '1rem',
  },
  ctaCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '20px',
    border: '1px solid #9487ED',
    '&__gradient': {
      background:
        'linear-gradient(130deg, rgba(68,46,224,1) 0%, rgba(29,6,56,1) 30%, rgba(29,6,56,1) 70%, rgba(68,46,224,1) 100%)',
    },
    '@media (max-width: 1400px)': {
      minWidth: '0',
    },
  },
  ctaTitle: {
    marginBottom: '1.25rem',
    fontSize: '3rem',
  },
  ctaCopy: {
    fontSize: '1.5rem',
  },
}));
